/**
 * This component is a proxy for the original <Video/> by Plasmic
 * and modifies its source so it points to Cloudinary and the poster prop is always optmized.
 * We replace Plasmic Video posterinstances with this component.
 */

import PropTypes from 'prop-types';
import {Video} from '@plasmicpkgs/plasmic-basic-components';
import useAvifSupport from '../hooks/useAvifSupport';

export default function PlasmicVideoWrapper({poster, ...props}) {
  const isString = typeof poster === 'string';
  const isCandidate = ['main', 'staging'].includes(process.env.GIT_BRANCH);
  const cloudinaryURL = `https://res.cloudinary.com/placeit-commercial/image/upload/f_auto/q_auto/v1/plasmic${poster}`;

  // Custom hook that indicates if Avif image format is supported by browser.
  const avifSupported = useAvifSupport();

  if (poster === undefined) return <Video {...props} />;

  if (isString && isCandidate && poster.endsWith('.png')) {
    const format = avifSupported ? 'avif' : 'webp';
    poster = cloudinaryURL
      .replace(/q_auto/, 'q_40')
      .replace(/f_auto/, `f_${format}`)
      .replace(/\.png$/, `.${format}`);
  } else {
    poster = cloudinaryURL.replace(/q_auto/, 'q_auto:eco');
  }

  return <Video {...props} poster={poster} />;
}

PlasmicVideoWrapper.propTypes = {
  poster: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      src: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

PlasmicVideoWrapper.defaultProps = {};
