import {forwardRef} from 'react';
import {useHits} from 'react-instantsearch';

/**
 * Higher-Order Component to add algolia hits data
 *
 * @param {React Component} Component - Component wrapped
 * @param {Object} props - Component props
 * @param {Object} algoliaProps - Algolia props
 *
 * @returns {DOMElement} - DOM Element with component algolia data
 */
export default function WithHits(Component, props, algoliaProps) {
  return forwardRef(function ComponentWithHits(_, ref) {
    const {hits} = useHits(algoliaProps);
    return <Component {...props} results={hits} ref={ref} />;
  });
}

/**
 * Higher-Order Component to add algolia nbHits data
 *
 * @param {React Component} Component - Component wrapped
 * @param {Object} props - Component props
 * @param {Object} algoliaProps - Algolia props
 *
 * @returns {DOMElement} - DOM Element with component algolia data
 */
export function WithTotalHits(Component, props, algoliaProps) {
  return forwardRef(function ComponentWithTotalHits(_, ref) {
    const {results} = useHits(algoliaProps);
    const {nbHits} = results;
    return <Component {...props} totalResults={nbHits} ref={ref} />;
  });
}
