import PropTypes from 'prop-types';
import {useRef} from 'react';
import InstantSearchProvider from '../Discovery/InstantSearchProvider';
import {WithGradientLinkButton, WithGradientShowMoreButton} from '../HOC/WithButton';
import WithHits, {WithTotalHits} from '../HOC/WithHits';
import GridWithBaseCards from './GridWithBaseCards';
import {defaultGridColumns, defaultGridColumnsPropTypes} from '../../lib/constants';
import ResultsCounter from '../ResultsCounter';

export default function AlgoliaGridWithGradientButton({algoliaConfig, gridConfig, buttonConfig}) {
  const {buttonAction} = buttonConfig;
  const {counterTitle, isCounterVisible} = gridConfig;
  const GridWithHits = WithHits(GridWithBaseCards, gridConfig);
  const ResultsCounterWithTotalHits = WithTotalHits(ResultsCounter, {title: counterTitle});
  const counterRef = useRef(null);
  const GridWithButton =
    buttonAction === 'redirect'
      ? WithGradientLinkButton(GridWithHits, buttonConfig)
      : WithGradientShowMoreButton(GridWithHits, {...buttonConfig, counterRef});
  return (
    <InstantSearchProvider {...algoliaConfig}>
      {isCounterVisible ? <ResultsCounterWithTotalHits ref={counterRef} /> : null}
      <GridWithButton />
    </InstantSearchProvider>
  );
}

AlgoliaGridWithGradientButton.displayName = 'AlgoliaGridWithGradientButton';

AlgoliaGridWithGradientButton.defaultProps = {
  algoliaConfig: {
    indexName: 'Stage_production',
    hitsPerPage: 16,
    filters: 'is_published=1',
    query: '',
  },
  gridConfig: {
    className: '',
    breakpointColumns: defaultGridColumns,
    counterTitle: 'New Templates!',
    isCounterVisible: true,
  },
  buttonConfig: {
    buttonAction: 'showmore',
    variant: 'capsule',
    text: '',
    linkTo: '',
    size: 'md',
  },
};

AlgoliaGridWithGradientButton.propTypes = {
  algoliaConfig: PropTypes.shape({
    indexName: PropTypes.string,
    hitsPerPage: PropTypes.number,
    filters: PropTypes.string,
    query: PropTypes.string,
  }),
  gridConfig: PropTypes.shape({
    className: PropTypes.string,
    breakpointColumns: defaultGridColumnsPropTypes,
    counterTitle: PropTypes.string,
    isCounterVisible: PropTypes.bool,
  }),
  buttonConfig: PropTypes.shape({
    buttonAction: PropTypes.oneOf(['redirect', 'showmore']),
    variant: PropTypes.oneOf(['primaryRegular', 'secondaryOutlined', 'tertiary', 'capsule']),
    text: PropTypes.string,
    linkTo: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  }),
};
