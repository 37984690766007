import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import Text from './UI/Atoms/Typography/Text';

const ResultsCounter = forwardRef(({title, totalResults}, ref) => {
  return (
    <div
      ref={ref}
      className="mb-4 flex w-full max-w-full items-center justify-start gap-x-3 px-2 leading-6 lg:px-3"
    >
      <Text size="3xl" weight="bold">
        {totalResults}
      </Text>
      <Text size="3xl">{title}</Text>
    </div>
  );
});
export default ResultsCounter;

ResultsCounter.displayName = 'ResultsCounter';
ResultsCounter.propTypes = {
  /**
   * The title displayed with the counter
   */
  title: PropTypes.string.isRequired,
  /**
   * Number displayed as Counter
   */
  totalResults: PropTypes.number.isRequired,
};
