import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import MasonryGrid from '../Discovery/Common/Grid/MasonryGrid';
import {defaultGridColumns, defaultGridColumnsPropTypes} from '../../lib/constants';
import BaseCard from '../UI/Card/BaseCard';
import ClickEventReporter from '../ClickEventReporter';
import useBucketsCdn from '../../hooks/useBucketsCdn';

/**
MasonryGrid using Base Card to display items.
*/
const GridWithBaseCards = forwardRef(
  (
    {
      results,
      className,
      breakpointColumns,
      isTitleVisible,
      itemRedirectTo,
      isEditorLink,
      eventName,
    },
    ref
  ) => {
    const {stageThumbUrl} = useBucketsCdn();

    return (
      <MasonryGrid
        ref={ref}
        className={`flex max-w-full px-2 lg:px-0 ${className}`}
        breakpointColumns={breakpointColumns}
      >
        {results?.map(data => {
          const link = isEditorLink ? `${data[itemRedirectTo]}/editor` : data[itemRedirectTo];
          return (
            <ClickEventReporter
              eventName={eventName}
              customProperties={{
                label: link,
              }}
              key={data.id}
            >
              <BaseCard
                link={link}
                image={stageThumbUrl(data.large_thumb_path)}
                altText={data.seo_title}
                title={isTitleVisible ? data.seo_title : null}
              />
            </ClickEventReporter>
          );
        })}
      </MasonryGrid>
    );
  }
);

export default GridWithBaseCards;

GridWithBaseCards.displayName = 'GridWithBaseCards';
GridWithBaseCards.propTypes = {
  /**
   * List of items that it will be shown in the grid.
   */
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      large_thumb: PropTypes.string.isRequired,
      preview_image_path: PropTypes.string,
      seo_title: PropTypes.string.isRequired,
    })
  ),
  /**
   * The optional CSS classes to be applied on the grid
   */
  className: PropTypes.string,
  /**
   * Number of columns shown in each resolution. See MasonryGrid component for more information.
   */
  breakpointColumns: defaultGridColumnsPropTypes,
  /**
   * Defines if the title will be visible.
   */
  isTitleVisible: PropTypes.bool,
  /**
   * Name of the property used to redirect users when the item is clicked
   */
  itemRedirectTo: PropTypes.string,
  /**
   * Defines if the link should be redirected to the editor
   */
  isEditorLink: PropTypes.bool,
  /**
   * Name of the tracking event sent when the item is clicked
   */
  eventName: PropTypes.string,
};

GridWithBaseCards.defaultProps = {
  results: [],
  className: '',
  breakpointColumns: defaultGridColumns,
  isTitleVisible: true,
  itemRedirectTo: 'stage_link',
  isEditorLink: false,
  eventName: 'carousel:latest:clicked',
};
