import PropTypes from 'prop-types';
// eslint-disable-next-line import/prefer-default-export
export const ALGOLIA_INDEXES = {
  staging: {
    Newest: 'Stage_staging_1_replica_newest',
    Mixed: 'Stage_staging_1',
    'Best Selling': 'Stage_staging_1_replica_best_selling',
  },
  production: {
    Newest: 'Stage_production_replica_newest',
    Mixed: 'Stage_production',
    'Best Selling': 'Stage_production_replica_best_selling',
  },
};

export const BUTTON_TYPES = {
  Primary: 'primaryRegular',
  'Secondary Outlined': 'secondaryOutlined',
  Tertiary: 'tertiary',
  Capsule: 'capsule',
};

export const BUTTON_ACTIONS = {
  'Show more': 'showmore',
  Redirect: 'redirect',
};

// We need to update the property names when the PDPs property will added to algolia data.
export const ITEM_REDIRECT_OPTIONS = {
  'Product Page': 'stage_link',
  'Editor Page': 'stage_link',
};

export const getIndexName = (env, sortBy) => {
  return ALGOLIA_INDEXES[env][sortBy];
};

export const getTimeForFiltering = days => {
  if (days < 1) {
    return 0;
  }
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - days);
  startDate.setHours(0, 0, 0, 0);
  return startDate.getTime() / 1000;
};

export const DEFAULT_PROPS = {
  sortBy: 'Mixed',
  hits: 20,
  filters: 'is_published=1 AND is_list=0',
  query: '',
  smCols: 2,
  mdCols: 3,
  lgCols: 4,
  xlCols: 6,
  '2xlCols': 6,
  '3xlCols': 6,
  itemRedirect: 'Product Page',
  buttonVariant: 'Capsule',
  buttonText: 'Explore More Templates',
  buttonSize: 'md',
  buttonLink: '#',
  daysForFilter: 14,
};

export const PROP_TYPES = {
  sortBy: PropTypes.oneOf(['Newest', 'Best Selling', 'Mixed']),
  hits: PropTypes.number,
  filters: PropTypes.string,
  query: PropTypes.string,
  smCols: PropTypes.number,
  mdCols: PropTypes.number,
  lgCols: PropTypes.number,
  xlCols: PropTypes.number,
  '2xlCols': PropTypes.number,
  '3xlCols': PropTypes.number,
  itemRedirect: PropTypes.oneOf(['Product Page', 'Editor Page']),
  buttonVariant: PropTypes.oneOf(['Primary', 'Secondary Outlined', 'Tertiary', 'Capsule']),
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  daysForFilter: PropTypes.number,
};

export const PLASMIC_PROPS = {
  sortBy: {
    type: 'choice',
    options: ['Newest', 'Best Selling', 'Mixed'],
    defaultValue: 'Newest',
    displayName: 'Sort choice',
    description: 'Sort in which the results are displayed',
  },
  hits: {
    type: 'number',
    defaultValue: 20,
    displayName: 'Total of results',
    description: 'Number of results shown',
  },
  filters: {
    type: 'string',
    defaultValue: 'is_published=1',
    displayName: 'Filters',
    description: 'Filters applied to the results',
  },
  query: {
    type: 'string',
    defaultValue: '',
    displayName: 'Query',
    description: 'Query applied to the results',
  },
  daysForFilter: {
    type: 'number',
    defaultValue: 14,
    displayName: 'Number of Days',
    description:
      'Number of days before today considered for results. If the value is 0, this filter is ignored.',
  },
  smCols: {
    type: 'number',
    defaultValue: 2,
    displayName: 'Columns in mobile resolution',
    description: 'Number of columns for mobile resolution. < 769px',
  },
  mdCols: {
    type: 'number',
    defaultValue: 2,
    displayName: 'Columns in tablet resolution',
    description: 'Number of columns for tablet resolution. 769px - 1024px',
  },
  lgCols: {
    type: 'number',
    defaultValue: 2,
    displayName: 'Columns in desktop resolution',
    description: 'Number of columns for desktop resolution. 1025px - 1280px',
  },
  xlCols: {
    type: 'number',
    defaultValue: 6,
    displayName: 'Columns in desktop XL resolution',
    description: 'Number of columns for desktop XL resolution. 1281px - 1536px',
  },
  '2xlCols': {
    type: 'number',
    defaultValue: 6,
    displayName: 'Columns in desktop 2XL resolution',
    description: 'Number of columns for desktop 2XL resolution. 1537px - 1900px',
  },
  '3xlCols': {
    type: 'number',
    defaultValue: 6,
    displayName: 'Columns in desktop 3XL resolution',
    description: 'Number of columns for desktop 3XL resolution. > 1900px',
  },
  itemRedirect: {
    type: 'choice',
    options: ['Product Page', 'Editor Page'],
    defaultValue: 'Product Page',
    displayName: 'Item Redirect',
    description: 'Page to redirect users when an item is clicked',
  },
  buttonVariant: {
    type: 'choice',
    options: ['Primary', 'Secondary Outlined', 'Tertiary', 'Capsule'],
    defaultValue: 'Capsule',
    displayName: 'Button appearance',
    description: 'Button appearance.',
  },
  buttonSize: {
    type: 'choice',
    options: ['sm', 'md', 'lg', 'xl'],
    defaultValue: 'md',
    displayName: 'Button size',
    description: 'Button size.',
  },
};
