import {useEffect, useState, useRef} from 'react';
import BaseButton from '../UI/Molecules/BaseButton';
import useNavbarHeight from '../../hooks/useNavbarHeight';

const BUTTON_LABELS = {
  open: 'Show less',
  close: 'Show more',
};

const GRADIENT_CLASSES = {
  open: 'max-h-[10000px]',
  close: 'overflow-hidden min-h-[580px] max-h-[585px]', // safari requires both min and max to be defined
};

/**
 * Higher-Order Component to add a button component
 *
 * @param {React Component} Component - Component wrapped
 * @param {Object {
 *   text: string;
 *   action: () => void;
 *   variant: string;
 *   size: string;
 *   classNames: Object;
 * }} config - Button configuration
 *
 * @returns {DOMElement} - DOM Element with component and button merged
 */
export default function WithButton(Component, config) {
  const {text, action, variant, size, classNames} = config;
  function ComponentWithButton() {
    return (
      <div className={classNames?.container}>
        <div className={classNames?.content}>
          <Component />
        </div>
        <div className={classNames?.buttonContainer}>
          <BaseButton variant={variant} size={size} className={classNames?.button} onClick={action}>
            {text}
          </BaseButton>
        </div>
      </div>
    );
  }
  return ComponentWithButton;
}

/**
 * Higher-Order Component to add a gradient button
 *
 * @param {React Component} Component - Component wrapped
 * @param {Object {
 *   text: string;
 *   action: () => void;
 *   variant: string;
 *   size: string;
 *   classNames: Object;
 * }} config - Button configuration
 *
 * @returns {DOMElement} - DOM Element with component and gradient button merged
 */
function WithGradientButton(Component, config) {
  const {text, action, variant, size, classNames} = config;
  return WithButton(Component, {
    text,
    action,
    variant,
    size,
    classNames: {
      container: `max-w-full relative ${classNames?.container ?? ''}`,
      content: `flex w-full pb-20 ${classNames?.content ?? ''}`,
      buttonContainer: `pt-14 pb-3 z-[1] absolute bg-gradient-to-b flex justify-center items-center from-transparent to-white w-full bottom-0 ${
        classNames?.buttonContainer ?? ''
      }`,
      button: classNames?.button,
    },
  });
}

/**
 * Higher-Order Component to add a gradient button with redirect action
 *
 * @param {React Component} Component - Component wrapped
 * @param {Object {
 *   text: string;
 *   action: () => void;
 *   variant: string;
 *   size: string;
 *   classNames: Object;
 * }} config - Button configuration
 *
 * @returns {DOMElement} - DOM Element with component and gradient button merged
 */
function WithGradientLinkButton(Component, config) {
  const {text, classNames, linkTo, size, variant} = config;
  return WithGradientButton(Component, {
    text,
    variant,
    size,
    classNames: {
      ...classNames,
      container: `${classNames?.container ?? ''} ${GRADIENT_CLASSES.close}`,
    },
    action: () => {
      window.location.href = linkTo;
    },
  });
}

/**
 * Higher-Order Component to add a gradient button with show more action
 *
 * @param {React Component} Component - Component wrapped
 * @param {Object {
 *   text: string;
 *   action: () => void;
 *   variant: string;
 *   size: string;
 *   classNames: Object;
 * }} config - Button configuration
 *
 * @returns {DOMElement} - DOM Element with component and gradient button merged
 */
function WithGradientShowMoreButton(Component, config) {
  const {classNames, variant, size, counterRef} = config;
  const [isOpen, setIsOpen] = useState(false);
  const [toggleClass, setToggleClass] = useState(GRADIENT_CLASSES.close);
  const [buttonText, setButtonText] = useState(BUTTON_LABELS.close);
  const componentRef = useRef(null);
  const navbarHeight = useNavbarHeight();
  function ComponentWithRef() {
    return <Component className={`min-h-[1000px] ${toggleClass}`} ref={componentRef} />;
  }

  useEffect(() => {
    if (window.scrollY > 0) {
      if (counterRef.current !== null) {
        window.scrollTo({
          top: window.scrollY + counterRef.current.getBoundingClientRect().top - navbarHeight,
          behavior: 'smooth',
        });
      } else if (componentRef.current !== null) {
        window.scrollTo({
          top: window.scrollY + componentRef.current.getBoundingClientRect().top - navbarHeight,
          behavior: 'smooth',
        });
      }
    }
    if (isOpen) {
      setToggleClass(GRADIENT_CLASSES.open);
      setButtonText(BUTTON_LABELS.open);
    } else {
      setToggleClass(GRADIENT_CLASSES.close);
      setButtonText(BUTTON_LABELS.close);
    }
  }, [isOpen]);

  return WithGradientButton(ComponentWithRef, {
    variant,
    size,
    text: buttonText,
    classNames: {
      ...classNames,
      container: `${
        classNames?.container ?? ''
      } ease-in-out transition-all duration-300 ${toggleClass}`,
    },
    action: () => {
      setIsOpen(!isOpen);
    },
  });
}

export {WithGradientButton, WithGradientLinkButton, WithGradientShowMoreButton};
