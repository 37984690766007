import PropTypes from 'prop-types';
import Text from '../Atoms/Typography/Text';

export default function BaseCard({link, image, altText, title}) {
  return (
    <a href={link} className="group peer flex flex-col">
      <div className="relative block w-full overflow-hidden rounded-lg bg-gray-200 shadow-sm">
        <div className="group relative h-full max-h-full w-full max-w-full">
          <img
            className="z-0 h-full w-full max-w-full object-cover transition-transform duration-500 group-hover:scale-110"
            src={image}
            alt={altText}
          />
        </div>
      </div>
      {title && (
        <Text size="lg" className="mt-1 truncate text-ellipsis text-gray-700">
          {title}
        </Text>
      )}
    </a>
  );
}

BaseCard.displayName = 'TemplateImageWithPreview';

BaseCard.defaultProps = {
  title: null,
};

BaseCard.propTypes = {
  /**
   * URL that will redirected by the card
   */
  link: PropTypes.string.isRequired,
  /**
   * Path of the image shown in the card
   */
  image: PropTypes.string.isRequired,
  /**
   * Alternative text shown in the card
   */
  altText: PropTypes.string.isRequired,
  /**
   * Title of the card
   */
  title: PropTypes.string,
};
