import PropTypes from 'prop-types';
import {useRouter} from 'next/router';
import {useMemo} from 'react';
import AlgoliaGridWithGradientButton from './AlgoliaGridWithGradientButton';
import {
  BUTTON_TYPES,
  BUTTON_ACTIONS,
  ITEM_REDIRECT_OPTIONS,
  DEFAULT_PROPS,
  PLASMIC_PROPS,
  PROP_TYPES,
  getIndexName,
  getTimeForFiltering,
} from '../../lib/plasmic-grid-constants';

export default function PlasmicAlgoliaGridWithGradientButton({
  sortBy,
  hits,
  filters,
  query,
  smCols,
  mdCols,
  lgCols,
  xlCols,
  '2xlCols': twoXCols,
  '3xlCols': threeXCols,
  counterTitle,
  isCounterVisible,
  isTitleVisible,
  itemRedirect,
  buttonAction,
  buttonVariant,
  buttonText,
  buttonSize,
  buttonLink,
  isEditorLink,
  daysForFilter,
  itemEventName,
}) {
  const router = useRouter();
  let environment = 'production';
  if (
    router &&
    'query' in router &&
    'algolia_staging' in router.query &&
    process.env.GIT_BRANCH !== 'main'
  ) {
    environment = 'staging';
  }

  const filterTime = useMemo(() => {
    const days = getTimeForFiltering(daysForFilter);
    if (days < 1) {
      return '';
    }
    return `AND date_for_filtering>=${days}`;
  }, [daysForFilter]);

  return (
    <AlgoliaGridWithGradientButton
      algoliaConfig={{
        indexName: getIndexName(environment, sortBy),
        hitsPerPage: hits,
        query,
        filters: `${filters} ${filterTime}`,
        shouldHideIndexName: true,
      }}
      gridConfig={{
        breakpointColumns: {
          sm: smCols,
          md: mdCols,
          lg: lgCols,
          xl: xlCols,
          '2xl': twoXCols,
          '3xl': threeXCols,
        },
        isTitleVisible,
        itemRedirectTo: ITEM_REDIRECT_OPTIONS[itemRedirect],
        counterTitle,
        isCounterVisible,
        isEditorLink,
        eventName: itemEventName,
      }}
      buttonConfig={{
        buttonAction: BUTTON_ACTIONS[buttonAction],
        variant: BUTTON_TYPES[buttonVariant],
        text: buttonText,
        linkTo: buttonLink,
        size: buttonSize,
      }}
    />
  );
}

PlasmicAlgoliaGridWithGradientButton.defaultProps = {
  ...DEFAULT_PROPS,
  buttonAction: 'Show more',
  isTitleVisible: true,
  counterTitle: 'New Templates!',
  isCounterVisible: true,
  itemEventName: 'carousel:latest:clicked',
};

PlasmicAlgoliaGridWithGradientButton.propTypes = {
  ...PROP_TYPES,
  counterTitle: PropTypes.string,
  isCounterVisible: PropTypes.bool,
  isTitleVisible: PropTypes.bool,
  buttonAction: PropTypes.oneOf(['Show more', 'Redirect']),
  itemEventName: PropTypes.string,
};

export const PlasmicProps = {
  name: 'Grid With Gradient Button',
  props: {
    ...PLASMIC_PROPS,
    isTitleVisible: {
      type: 'boolean',
      defaultValue: true,
      displayName: 'Is Item Title Visible?',
      description: 'Item title visibility.',
    },
    isCounterVisible: {
      type: 'boolean',
      defaultValue: true,
      displayName: 'Is Counter Visible?',
      description: 'Counter of results visibility.',
    },
    counterTitle: {
      type: 'string',
      defaultValue: 'New Templates!',
      displayName: 'Counter Title',
      description: 'Title displayed with the counter',
      hidden: props => !props.isCounterVisible,
    },
    buttonAction: {
      type: 'choice',
      options: ['Show more', 'Redirect'],
      defaultValue: 'Show more',
      displayName: 'Button action',
      description: 'Action when the button is clicked',
    },
    buttonText: {
      type: 'string',
      defaultValue: 'Explore More Templates',
      displayName: 'Button text',
      description: 'Text displayed in the button.',
      hidden: props => props.buttonAction === 'Show more',
    },
    buttonLink: {
      type: 'string',
      defaultValue: '#',
      displayName: 'Button link',
      description:
        'Page to be redirected when the button is clicked. The URL would be relative or absolute.',
      hidden: props => props.buttonAction === 'Show more',
    },
    isEditorLink: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Is Editor Link?',
      description: 'Grid items redirect to `/editor`.',
    },
    itemEventName: {
      type: 'string',
      defaultValue: 'carousel:latest:clicked',
      displayName: 'Item Event Name',
      description: 'Tracking event sent when a grid item will be clicked.',
    },
  },
  importPath: './components/Grid/PlasmicAlgoliaGridWithGradientButton',
  isDefaultExport: true,
};
